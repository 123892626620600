<template>
    <div v-if="form" class="page-editor-share">
        <TopHeader nav="Editor" :data="form">
            <Btn @click.native="showPreview(form)" type="secondary" size="sm"><Icon name="eye" /> Ver</Btn>
        </TopHeader>

        <main class="page-content">
            <section class="options-column">
                <div class="share-header">
                    
                </div>
                <div class="share-link">
                    <h3>Link</h3>
                    <p>Envie esse link por e-mail, ou compartilhe nas suas redes sociais.</p>
                    <div class="share-link-container mt8">
                        <TextField :data="{}" :value="publicUrl" class="url-field" readonly />                
                        <Btn 
                            v-clipboard:copy="publicUrl"
                            @click.native='copyUrl'
                            v-tooltip="{ content: 'Copiado!', placement:'bottom', trigger: 'click' }"
                            class="ml8 copy"                    
                            type="positive"                    
                            >Copiar
                        </Btn>
                    </div>
                    <div class="share-social mt8">
                        <Icon name="share" />
                        <Btn :href="shareUrl.fb" size="xs" layout="icon">Facebook</Btn>
                        <Btn :href="shareUrl.tw" size="xs" layout="icon">Twitter</Btn>
                        <Btn :href="shareUrl.in" size="xs" layout="icon">Linkedin</Btn>
                    </div>
                </div>                

                <div class="share-embed mt32">
                    <div class="embed-type">
                        <h2>Código Embed</h2>
                        <p>Utilize esse código para adiconar o formulário no seu site. Você pode customizar o formato.</p>
                        <SelectField v-model="embed.mode" :options="embedOptions" :value="embedOptions[0].value" class="mt16" />
                    </div>

                    <!-- Regular -->
                    <div class="embed-options mt16">
                        <div v-if="embed.mode == 'regular'" class="options-regular">
                            <div class="field-label-container">
                                <div class="field-label">Largura</div>
                                <TextField v-model="embed.width.value" :data="{}" />
                                <SelectField class="unit-select ml8"  v-model="embed.width.unit" :options="embedSizeOptions" :value="embedSizeOptions[0].value" />
                            </div>
                            <div class="field-label-container mt16">
                                <div class="field-label">Altura</div>
                                <TextField v-model="embed.height.value" :data="{}" />
                                <SelectField class="unit-select ml8" v-model="embed.height.unit" :options="embedSizeOptions" :value="embedSizeOptions[0].value" />
                            </div>      
                        </div>

                        <!-- Button -->
                        <div v-if="embed.mode == 'buttonModal'" class="options-button">
                            <div class="field-label-container">
                                <div class="field-label">Texto</div>
                                <TextField v-model="embed.buttonText" :data="{}" />
                            </div>
                            <div class="field-label-container mt16">
                                <div class="field-label">Tamanho</div>
                                <TextField v-model="embed.buttonTextSize" :data="{}" />
                            </div>
                            <div class="field-label-container mt16">
                                <div class="field-label">Cor</div>
                                <div class="color">
                                    <ColorPickerField v-model="embed.buttonColor" :startValue="embed.buttonColor" />
                                    <TextField v-model="embed.buttonColor" :data="{}" class="mt8" />
                                </div>
                            </div>      
                        </div>
                    </div>


                    <div class="embed-code mt32">
                        <Btn @click.native="updateCode">Gerar código</Btn>

                    </div>
                </div>
            </section>

            <!-- Modal -->
            <Modal @modal-close='closeModal' title="Adicionar formulário ao seu site" :active="modalStatus">
                <div class="modal-body">
                    <p>Copie e cole este código no local que você deseja que o formulário apareça.</p>
                        
                    <code class="code-snnipet mt16 mb16">
                        {{embedCode}}
                    </code>

                    <Btn 
                        ref="btnCopyCode"
                        v-clipboard:copy="embedCode"
                        @click.native="copyCode($event)">
                        Copiar código</Btn>
                        
                        <!-- <textarea v-model="embedCode"></textarea> -->
                </div>
            </Modal>

            <!-- Embed Preview -->
            <section class="preview-column">
                <div class="preview-container">
                    <div class="preview-header mb16">Exemplo de como ficará no seu site</div>
                    <div class="preview-template">
                        <div class="template-regular" :data-mode="embed.mode">
                            <div class="placeholder-row"></div>
                            <div class="placeholder-row"></div>
                            <div class="placeholder-row"></div>
                            <div class="placeholder-row"></div>
                            <div class="embed-block mb16" :style="{backgroundColor: embed.buttonColor, fontSize: (embed.buttonTextSize / 2) + 'px' }">{{embedPlaceholder}}</div>
                            <div class="placeholder-row"></div>
                            <div class="placeholder-row"></div>
                            <div class="placeholder-row"></div>
                            <div class="placeholder-row"></div>
                        </div>
                    </div>
                </div>
            </section>
        </main>


    </div>
</template>

<script>
import TextareaField from '../components/form/TextareaField.vue'
import Modal from '../components/Modal.vue'
import ColorPickerField from '../components/form/ColorPickerField.vue'
import SelectField from '../components/form/SelectField.vue'
import TextField from '../components/form/TextField.vue'
import Icon from '../components/Icon.vue'
import Btn from '../components/form/Btn.vue'
import TopHeader from '../components/TopHeader.vue'
import { FormMixin } from '@/mixins/FormMixin';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

    export default {
        name: "EditorShare",
        components: { TopHeader, Btn, Icon, TextField, SelectField, ColorPickerField, Modal, TextareaField },
        mixins: [FormMixin, UtilitiesMixin],
        data() {
            return {
                modalStatus: false,
                embedCode: null,
                embedPlaceholder: "Seu formulário aqui",
                embed: {
                    mode: "regular",
                    width: {value: 100, unit: "%"},
                    height: {value: 600, unit: "px"},
                    buttonColor: "#7CB342",
                    buttonText: "Responder formulário",
                    buttonTextSize: 24,
                },
                embedOptions: [
                    {label: "Normal", value: "regular"},
                    {label: "Tela cheia", value: "fullscreen"},
                    {label: "Botão", value: "buttonModal"},
                ],
                embedSizeOptions: [
                    {label: "px", value: "px"},
                    {label: "%", value: "%"},
                ],

            }
        },
        computed: {
            publicUrl() {
                const resolved = this.$router.resolve({
                    name: 'FormView',
                    params: { slug: this.slug }
                })
                return process.env.VUE_APP_URL + resolved.href
            },



            shareUrl(){
                const text = "Participe dessa pesquisa: "
                const data = {                                    
                    fb: "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(this.publicUrl),
                    in: "https://www.linkedin.com/shareArticle/?url=" + encodeURI(this.publicUrl),
                    tw: "https://twitter.com/intent/tweet?text=" + encodeURI(text) + encodeURI(this.publicUrl),
                    }
                return data
                }
            

        },

        watch: {
            'embed.mode'(newValue, oldValue) {
                if(this.embed.mode == "buttonModal") this.embedPlaceholder = this.embed.buttonText
                else this.embedPlaceholder = "Seu formulário aqui"
            },
            'embed.buttonText'(value){
                this.embedPlaceholder = value
            }
        },

        methods: {

            copyCode(event){
                event.target.innerText = "Copiado!"
                this.$trackEvent.mixpanel("Copy Form Embed", {form: this.slug, type: this.embed.mode})

            },

            copyUrl(){
                this.$trackEvent.mixpanel("Copy Form Link", {form: this.slug})
            },

            generateCode() {                
                const element = document.createElement('div')
                element.setAttribute("data-respondi-container", "")
                element.setAttribute("data-respondi-mode", this.embed.mode)
                element.setAttribute("data-respondi-src", this.publicUrl)

                if(this.embed.mode == "regular"){
                    element.setAttribute("data-respondi-width", this.embed.width.value + this.embed.width.unit)
                    element.setAttribute("data-respondi-height", this.embed.height.value + this.embed.height.unit)
                }
                
                if(this.embed.mode == "buttonModal"){
                    element.setAttribute("data-respondi-buttonlabel", this.embed.buttonText)
                    element.setAttribute("data-respondi-buttoncolor", this.embed.buttonColor)
                    element.setAttribute("data-respondi-buttonsize", this.embed.buttonTextSize)
                    const button = document.createElement('div')
                    button.setAttribute("data-respondi-openbutton", "")
                    button.style.background = this.embed.buttonColor
                    button.style.fontSize = this.embed.buttonTextSize + "px"
                    button.style.padding = "8px 16px"
                    button.style.color = "#fff"
                    button.style.display = "inline-block"
                    button.innerText = this.embed.buttonText
                    element.appendChild(button)
                    //<div data-respondi-openbutton="" style="background: rgb(233, 30, 99);">Responder formulário</div>
                }

                const html = element.outerHTML

                const script = `<script>const bodyScript=document.querySelector('body'),hasScript=document.querySelector('#respondi_src');if(!hasScript){const script=document.createElement('script');script.setAttribute("async", "");document.body.appendChild(script),script.id='respondi_src',script.src='https://embed.respondi.app/embed.js';}<\/script>`
                return html + script
            },

            updateCode(){
                // this.$refs.btnCopyCode.$el.innerText = "Copiar código"
                this.embedCode = this.generateCode()
                this.modalStatus = true
            },

            closeModal(){
                this.modalStatus = false
            }
        },

    }
</script>

<style lang="sass" scoped>

.page-editor-share
    height: calc(100% - 50px)

    .social-icon
        display: flex
        height: 20px
        width: 20px
        background: #1565C0
        color: #fff
        align-items: center
        justify-content: center

    .code-snnipet
        display: block
        line-break: anywhere
        padding: $mgSm
        background: #37474F
        color: $cG1
        border-radius: 5px
        line-height: 120%
        font-size: $fzXxs


.page-content
    display: grid
    grid-template-columns: minmax(300px, 400px) auto
    grid-template-rows: 100% 100%
    grid-template-areas: "settings preview"
    // height: 100%
    
    .options-column
        grid-area: settings
        padding: $mg
        font-size: $fzXs
        line-height: 140%

        .unit-select
            width: 100px

        .share-link
            padding-bottom: $mg
            border-bottom: 1px solid $cG2


        .share-link-container
            display: flex

        .field-label-container
            display: flex
            align-items: center

            .field-label
                width: 120px



    .preview-column
        grid-area: preview
        display: flex
        align-items: center
        justify-content: center
        background: $cG2

        .preview-container
            width: 100%
            // height: 100%
            max-width: 500px
            // max-height: 400px

        .preview-header
            color: $cG5
            font-size: $fzSm
            text-align: center

        .preview-template
            width: 100%
            // height: 100%
            background: #fff
            border-radius: 5px
            box-shadow: 4px 4px 20px rgba(0,0,0,.1)
            padding: $mgSm $mgLg
            position: relative

            .placeholder-row
                background: $cG2
                width: 100%
                height: 8px
                border-radius: 3px
                margin-bottom: $mgXs

            .embed-block
                width: 100%
                height: 200px                
                border-radius: 5px
                display: flex
                align-items: center
                justify-content: center
                color: #fff
                transition: all .5s

            [data-mode="regular"]
                .embed-block
                    transition: all .5s
                    height: 200px
                    background: $cPrimary !important
                    font-size: $fzSm !important


            [data-mode="buttonModal"]
                min-height: 300px
                text-align: center

                .embed-block
                    width: auto
                    height: auto
                    display: inline-block
                    padding: $mgXs $mgSm
                    cursor: pointer
                    box-shadow: 2px 2px 2px rgba(0,0,0, .15)   
                    transition: all .5s
                    background: unset
                    
             


            [data-mode="fullscreen"]
                min-height: 300px

                .embed-block
                    position: absolute
                    width: 100%
                    height: 100%
                    top: 0
                    left: 0
                    transition: all .5s
                    background: $cPrimary !important
                    font-size: $fzSm !important


</style>